import "./styles.css";
import { useRef, useState, useEffect } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion";

import { gsap } from "gsap";

import { useScrollDirection } from "react-use-scroll-direction";

import { wrap } from "@motionone/utils";

import IsVisible from "./IsVisible";

import { SlVolume2, SlVolumeOff } from "react-icons/sl";

/*
import { Poster1 } from "./Posters/Poster1.jsx";
import { Poster2 } from "./Posters/Poster2.jsx";
import { Poster3 } from "./Posters/Poster3.jsx";
import { Poster4 } from "./Posters/Poster4.jsx";
import { Poster5 } from "./Posters/Poster5.jsx";
import { Poster6 } from "./Posters/Poster6.jsx";
*/

function ParallaxText({
  className,
  children,
  baseVelocity = 100,
}: ParallaxProps) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    /**
     * This is what changes the direction of the scroll once we
     * switch scrolling directions.
     */
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  /**
   * The number of times to repeat the child text should be dynamically calculated
   * based on the size of the text and viewport. Likewise, the x motion value is
   * currently wrapped between -20 and -45% - this 25% is derived from the fact
   * we have four children (100% / 4). This would also want deriving from the
   * dynamically generated number of children.
   */
  return (
    <div className={`parallax ${className}`}>
      <motion.div className="scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
}

function throttle(callback, limit) {
  var waiting = false; // Initially, we're not waiting
  return function () {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      callback.apply(this, arguments); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  };
}

let VOLUME_MAX = 0.5;
let NEXT_AUDIO = null;
let ACTIVE_AUDIO = null;

const fadeOutActive = async (callback) => {
  if (!ACTIVE_AUDIO) {
    if (callback) callback();
    return;
  }
  // Just fade out the active one
  let obj = { val: ACTIVE_AUDIO.volume };
  gsap.to(obj, {
    val: 0,
    duration: 0.5,
    onUpdate: () => {
      if (ACTIVE_AUDIO) ACTIVE_AUDIO.volume = obj.val.toFixed(2);
    },
    onComplete: async () => {
      if (ACTIVE_AUDIO) {
        await ACTIVE_AUDIO.pause(); //pause to update to unmuted
        ACTIVE_AUDIO.muted = true; //make sure vid is muted
        await ACTIVE_AUDIO.play();
      }

      if (callback) callback();
    },
  });
};

const muteAllVideos = () => {
  let vids = document.querySelectorAll("video");
  vids.forEach((v) => {
    v.muted = true;
    v.volume = 0;
  });
};

const fadeInActive = async (callback) => {
  if (!ACTIVE_AUDIO) {
    if (callback) callback();
    return;
  }

  await ACTIVE_AUDIO.pause(); //pause to update to unmuted
  ACTIVE_AUDIO.muted = false; //unmute
  ACTIVE_AUDIO.volume = 0;
  await ACTIVE_AUDIO.play();

  let obj = { val: 0 };
  gsap.to(obj, {
    val: VOLUME_MAX,
    duration: 1.5,
    onUpdate: () => {
      if (ACTIVE_AUDIO) ACTIVE_AUDIO.volume = obj.val.toFixed(2);
    },
    onComplete: () => {
      if (callback) callback();
    },
  });
};

const crossFade = (newVid) => {
  if (!newVid) {
    fadeOutActive(() => {
      ACTIVE_AUDIO = null;
      NEXT_AUDIO = null;
    });
  } else {
    fadeOutActive(() => {
      ACTIVE_AUDIO = newVid;
      fadeInActive();
    });
  }
};

const Video = ({ src, active }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      if (active) {
        NEXT_AUDIO = videoRef.current;
        crossFade(videoRef.current);
      } else {
        setTimeout(() => {
          if (NEXT_AUDIO === videoRef.current) {
            // If this is in active, and no new video is queued, fade it out
            crossFade(null);
          }
        }, 50);
      }
    }
  }, [active]);

  return (
    <div className={active ? "video active" : "video inactive"}>
      <video ref={videoRef} src={src} muted loop autoPlay preload playsInline />
    </div>
  );
};

export default function App() {
  const scrollDir = useRef("DOWN");
  const { scrollDirection } = useScrollDirection();

  // Use a ref to hold on to the last non-null value
  if (scrollDirection) scrollDir.current = scrollDirection;

  const { scrollY } = useScroll();

  // Array of visible items
  const activeRefs = useRef([]);

  const [allowAudio, setAllowAudio] = useState(false);
  const [activeVideo, setActiveVideo] = useState(0);

  const [scrolled, setScrolled] = useState(false);
  const [ending, setEnding] = useState(false);

  const $el = useRef(null);

  // Update the active video (if different to the ref)
  const setActive = (num) => {
    if (!num) {
      activeRefs.current = [];
    } else if (activeRefs.current.indexOf(num) === -1) {
      if (scrollDir.current !== "UP") {
        activeRefs.current.push(num);
      } else {
        activeRefs.current.unshift(num);
      }
    }
    setActiveVideo(num);
  };
  const unsetActive = (num) => {
    const index = activeRefs.current.indexOf(num);
    if (index > -1) {
      // only splice array when item is found
      activeRefs.current.splice(index, 1); // 2nd parameter means remove one item only

      // If no active refs, remove any playing sound
      /*setTimeout(() => {
        if (!activeRefs.current.length) crossFade(null);
      }, 50);*/
    }
  };

  const isActive = (num) => {
    if (!allowAudio) return false;
    if (scrollDir.current !== "UP") {
      let lastItem = activeRefs.current[activeRefs.current.length - 1];
      return num === lastItem;
    } else {
      let firstItem = activeRefs.current[0];
      return num === firstItem;
    }
  };

  useAnimationFrame(() => {
    // Upate scroll direction

    if (scrollY.current > 0) {
      // Has scrolled, update state
      if (!scrolled) setScrolled(true);
    } else {
      // Scroll at top, set state
      if (scrolled) setScrolled(false);
    }

    if ($el.current) {
      let h = $el.current.offsetHeight;
      let scrollableH = h - window.innerHeight * 1.5;

      //console.log(scrollableH,scroll)
      if (scrollY.current >= scrollableH) {
        // At the end, update state
        if (!ending) setEnding(true);
      } else {
        // Not at the end, update state
        if (ending) setEnding(false);
      }
    }
  });

  return (
    <div
      ref={$el}
      className={`App App--${ending ? "ending" : "progressing"} App--${
        scrolled ? "scrolled" : "top"
      }`}
      style={{ position: "relative" }}
    >
      <div className="scroll">
        <span className="scroll-text">Scroll</span>
        <span className="scroll-line" />
      </div>
      <div
        style={{
          height: "80vh",
        }}
      />
      <div
        className="item"
        style={{
          width: "80%",
          maxWidth: "600px",
          maxWidth: "60vh",
          margin: "20vmax auto",
        }}
      >
        <IsVisible
          onVisible={() => {
            setActive(1);
          }}
          onInvisible={() => {
            unsetActive(1);
          }}
        >
          <Video src={`/mp4/01.mp4`} active={isActive(1)} />
        </IsVisible>
      </div>
      <div
        className="item"
        style={{
          width: "80%",
          maxWidth: "600px",
          maxWidth: "60vh",
          margin: "20vmax auto",
        }}
      >
        <IsVisible
          onVisible={() => {
            setActive(2);
          }}
          onInvisible={() => {
            unsetActive(2);
          }}
        >
          <Video src={`/mp4/02.mp4`} active={isActive(2)} />
        </IsVisible>
      </div>
      <div
        className="item"
        style={{
          width: "80%",
          maxWidth: "600px",
          maxWidth: "60vh",
          margin: "20vmax auto",
        }}
      >
        <IsVisible
          onVisible={() => {
            setActive(3);
          }}
          onInvisible={() => {
            unsetActive(3);
          }}
        >
          <Video src={`/mp4/03.mp4`} active={isActive(3)} />
        </IsVisible>
      </div>
      <div
        className="item"
        style={{
          width: "80%",
          maxWidth: "600px",
          maxWidth: "60vh",
          margin: "20vmax auto",
        }}
      >
        <IsVisible
          onVisible={() => {
            setActive(4);
          }}
          onInvisible={() => {
            unsetActive(4);
          }}
        >
          <Video src={`/mp4/04.mp4`} active={isActive(4)} />
        </IsVisible>
      </div>
      <div
        className="item"
        style={{
          width: "80%",
          maxWidth: "600px",
          maxWidth: "60vh",
          margin: "20vmax auto",
        }}
      >
        <IsVisible
          onVisible={() => {
            setActive(5);
          }}
          onInvisible={() => {
            unsetActive(5);
          }}
        >
          <Video src={`/mp4/05.mp4`} active={isActive(5)} />
        </IsVisible>
      </div>
      <div
        className="item"
        style={{
          width: "80%",
          maxWidth: "600px",
          maxWidth: "60vh",
          margin: "20vmax auto",
        }}
      >
        <IsVisible
          onVisible={() => {
            setActive(6);
          }}
          onInvisible={() => {
            unsetActive(6);
          }}
        >
          <Video src={`/mp4/06.mp4`} active={isActive(6)} />
        </IsVisible>
      </div>
      <div
        className="ending"
        style={{
          fontFamily: "serif",
          textAlign: "center",
          fontStyle: "italic",
        }}
      >
        <div>
          Each ending is a beginning
          <br />
          Join us for a year of revolutions.
        </div>
      </div>
      <div className="handle">
        <a href="https://www.instagram.com/heredesign/" target="_blank">
          @heredesign
        </a>
      </div>
      <section
        className="banner"
        style={{
          position: "fixed",
          top: "50%",
          transform: "translateY(-50%)",
          height: "auto",
          left: 0,
          width: "100%",
          zIndex: -1,
        }}
      >
        <ParallaxText className="alt" baseVelocity={-5}>
          Endings &
        </ParallaxText>
        <ParallaxText baseVelocity={5}>Beginnings &</ParallaxText>
      </section>

      <div className="sound" onClick={() => setAllowAudio(!allowAudio)}>
        {allowAudio ? <SlVolume2 /> : <SlVolumeOff />}
      </div>
    </div>
  );
}
