import React, { Component } from "react";
import VisibilitySensor from "react-visibility-sensor";

class IsVisible extends Component {
  hasSeen = 0;

  render() {
    let { children } = this.props;
    let className = this.props.className || "";

    let partial = this.props.hasOwnProperty("partialVisibility")
      ? this.props.partialVisibility
      : true;

    let isPortrait = window.innerHeight >= window.innerWidth;
    let offset = isPortrait
      ? window.innerHeight * 0.4
      : window.innerHeight * 0.2;

    return (
      <VisibilitySensor
        onChange={this.props.onChange}
        partialVisibility={partial}
        offset={{
          top: offset,
          bottom: offset,
        }}
      >
        {({ isVisible }) => {
          if (isVisible) {
            if (this.props.onVisible) {
              this.props.onVisible(this.hasSeen);
            }
          } else {
            if (this.props.onInvisible) {
              this.props.onInvisible(this.hasSeen);
            }
          }
          if (isVisible) this.hasSeen = 1;

          if (this.props.once) {
            if (isVisible || this.hasSeen) {
              return this.props.once();
            } else {
              return this.props.fallback();
            }
          }

          let visibleClass = isVisible ? "visible" : "invisible";
          let hasSeenClass = this.hasSeen ? "has-seen" : "";

          let Tag = this.props.tag || "div";
          return typeof children === "function" ? (
            children({ visible: isVisible })
          ) : (
            <Tag
              className={className + " is-" + visibleClass + " " + hasSeenClass}
            >
              {children}
            </Tag>
          );
        }}
      </VisibilitySensor>
    );
  }
}

export default IsVisible;
export { IsVisible };
