export function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="21.545"
      viewBox="0 0 76 21.545"
    >
      <g id="Group_27" data-name="Group 27" transform="translate(-46 -2428.54)">
        <g id="Group_26" data-name="Group 26" transform="translate(46 2428.54)">
          <path
            id="Path_19"
            data-name="Path 19"
            d="M342.513,521.258c.993,0,3.458.129,4.578.129,1.186,0,3.235-.1,4.866-.129V522c-3.073.03-3.073.575-3.073,5.89v3.169h11.43v-3.425c0-5.219-.129-5.571-3.076-5.634v-.737c1.571.033,3.747.129,4.677.129,1.279,0,3.169-.1,4.77-.129V522c-3.076,0-3.076.671-3.076,5.86v7.81c0,5.507,0,6.019,3.076,6.052v.734c-3.169-.1-4.258-.127-4.644-.127-.352,0-.993.03-4.8.127v-.734c2.946-.033,3.076-.481,3.076-5.444V532.08h-11.43v4.225c0,4.993.192,5.378,3.073,5.411v.734c-1.023,0-4-.127-4.641-.127-.352,0-2.787.1-4.8.127v-.734c3.042,0,3.073-.608,3.073-5.8V527.6c0-4.993-.063-5.6-3.073-5.6Z"
            transform="translate(-342.513 -521.258)"
          />
          <path
            id="Path_20"
            data-name="Path 20"
            d="M365.454,529c0,.289-.063.319-.415.319h-9.571a10.748,10.748,0,0,0-.129,1.538c0,3.362,1.824,6.434,5.026,6.434a5.25,5.25,0,0,0,4.033-2.369l.674.671a6.835,6.835,0,0,1-5.7,2.721c-3.9,0-7.235-2.784-7.235-6.817a7.427,7.427,0,0,1,7.2-7.683C362.607,523.819,365.454,525.706,365.454,529Zm-4.129-.545c.9,0,1.153-.193,1.153-.671a2.921,2.921,0,0,0-3.136-3.235c-1.923,0-3.2,1.216-3.714,3.906Z"
            transform="translate(-325.662 -516.774)"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M362.581,526.8v.256h.063a4.9,4.9,0,0,1,4.644-3.232c1.249,0,2.272.638,2.272,1.664a1.162,1.162,0,0,1-1.12,1.153c-1.345,0-1.056-1.667-2.305-1.667a3.3,3.3,0,0,0-2.721,2.082,8.728,8.728,0,0,0-.512,3.585v4.707c0,1.664.638,1.89,2.432,1.986v.638c-1.728-.063-3.425-.126-3.681-.126-.289,0-1.73.063-3.714.126v-.638c1.791-.129,2.239-.514,2.239-2.113v-5.284a14.864,14.864,0,0,0-.223-3.585c-.322-.833-.608-.9-2.083-.9v-.641a29.988,29.988,0,0,0,4.325-.991A10.335,10.335,0,0,1,362.581,526.8Z"
            transform="translate(-315.617 -516.774)"
          />
          <path
            id="Path_22"
            data-name="Path 22"
            d="M375.928,529c0,.289-.063.319-.415.319H365.94a11.168,11.168,0,0,0-.127,1.538c0,3.362,1.824,6.434,5.026,6.434a5.258,5.258,0,0,0,4.033-2.369l.671.671a6.83,6.83,0,0,1-5.7,2.721c-3.906,0-7.235-2.784-7.235-6.817a7.425,7.425,0,0,1,7.2-7.683C373.078,523.819,375.928,525.706,375.928,529Zm-4.129-.545c.9,0,1.153-.193,1.153-.671a2.921,2.921,0,0,0-3.139-3.235c-1.92,0-3.2,1.216-3.714,3.906Z"
            transform="translate(-307.323 -516.774)"
          />
          <path
            id="Path_23"
            data-name="Path 23"
            d="M372.462,529.525a1.856,1.856,0,0,1-1.824,1.887,1.825,1.825,0,1,1,1.824-1.887Z"
            transform="translate(-296.462 -509.866)"
          />
        </g>
      </g>
    </svg>
  );
}
